import React from 'react';
import { Navigate } from 'react-router-dom';
import { auth } from './firebase'; // Adjust the path as necessary

const ProtectedRoute = ({ children }) => {
    if (!auth.currentUser) {
        // Redirect to the sign-in page if the user is not authenticated
        return <Navigate to="/signin" replace />;
    }

    return children;
};

export default ProtectedRoute;

import React, { useState } from 'react';
import './MainContent.css';
import { firestore } from '../../firebase'; // Adjust the import path based on your folder structure
import { collection, query, where, getDocs } from 'firebase/firestore';

function MainContent() {
  const [searchKeyword, setSearchKeyword] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [noResults, setNoResults] = useState(false);

  // Function to handle the search operation
  const handleSearch = async () => {
    try {
      // New Modular SDK syntax for queries
      const productsRef = collection(firestore, 'products');
      const q = query(productsRef, where('name', '==', searchKeyword));

      const querySnapshot = await getDocs(q);

      const results = [];
      querySnapshot.forEach((doc) => {
        // New Modular SDK doesn't require doc.data().exists check
        results.push(doc.data());
      });

      if (results.length === 0) {
        setNoResults(true);
      } else {
        setNoResults(false);
      }

      setSearchResults(results);
    } catch (error) {
      console.error('Error searching products: ', error);
    }
  };

  return (
    <div>
  
      <div className="search-bar">
        <input
          type="text"
          value={searchKeyword}
          onChange={(e) => setSearchKeyword(e.target.value)}
          placeholder="Search For Links..."
        />
        <button onClick={handleSearch}>Search</button>
      </div>
      {/* Display search results or "No Results" message */}
      <div className="search-results">
        {noResults ? (
          <p>No Results</p>
        ) : (
          searchResults.map((result, index) => (
            <div key={index}>
              <h3>{result.name}</h3>
              <p>Website: <a href={result.website} target="_blank" rel="noopener noreferrer">{result.website}</a></p>
              <img
                src={result.image}
                alt={result.name}
                style={{ maxWidth: '250px', maxHeight: '250px' }} // Set max width and height
              />
            </div>
          ))
        )}
      </div>
    </div>
  );
}

export default MainContent;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth"; // Import getAuth and signInWithEmailAndPassword
import './SignIn.css';

function SignIn() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const navigate = useNavigate();
    const auth = getAuth(); // Get the auth instance

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await signInWithEmailAndPassword(auth, email, password); // Use signInWithEmailAndPassword
            setSuccessMessage('Sign in successful');
            setTimeout(() => navigate('/admin'), 1000); // Redirect after 2 seconds
        } catch (error) {
            console.error("Error signing in: ", error);
            setSuccessMessage('');
            // Optionally, show an error message
        }
    };

    return (
        <div className="sign-in">
            <form onSubmit={handleSubmit}>
                <input 
                    type="email" 
                    value={email} 
                    onChange={(e) => setEmail(e.target.value)} 
                    placeholder="Email"
                />
                <input 
                    type="password" 
                    value={password} 
                    onChange={(e) => setPassword(e.target.value)} 
                    placeholder="Password"
                />
                <button type="submit">Sign In</button>
            </form>
            {successMessage && <div className="success-message">{successMessage}</div>}
        </div>
    );
}

export default SignIn;

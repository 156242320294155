import React, { useState } from 'react';
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import './AdminPanel.css';

function AdminPanel() {
  const [name, setName] = useState('');
  const [productId, setProductId] = useState('');
  const [image, setImage] = useState(null); // Store the selected image file
  const [website, setWebsite] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const firestore = getFirestore();
  const storage = getStorage();

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    setImage(selectedImage);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!name || !productId || !image || !website) {
        throw new Error('Please fill in all fields');
      }

      // Upload the image to Firebase Storage
      const imageRef = ref(storage, `images/${image.name}`);
      await uploadBytes(imageRef, image);

      // Get the download URL of the uploaded image
      const imageUrl = await getDownloadURL(imageRef);

      // Add the product details including the image URL to Firestore
      await addDoc(collection(firestore, 'products'), {
        name,
        productId,
        image: imageUrl,
        website,
      });

      setName('');
      setProductId('');
      setImage(null);
      setWebsite('');
      setSuccessMessage('Successful Upload');
      setTimeout(() => setSuccessMessage(''), 3000);
    } catch (error) {
      console.error('Error adding product: ', error);
      setSuccessMessage('');
    }
  };

  return (
    <div className="admin-panel">
      <h2>Admin Panel</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Product Name"
        />
        <input
          type="text"
          value={productId}
          onChange={(e) => setProductId(e.target.value)}
          placeholder="Product ID"
        />
        <input
          type="file"
          accept="image/*"
          onChange={handleImageChange}
        />
        <input
          type="text"
          value={website}
          onChange={(e) => setWebsite(e.target.value)}
          placeholder="Website Link"
        />
        <button type="submit">Add Product</button>
      </form>
      {successMessage && <div className="success-message">{successMessage}</div>}
    </div>
  );
}

export default AdminPanel;

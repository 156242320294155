import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

function Navbar() {
  return (
    <nav>
      <Link to="/">Home</Link>
      <h4>The Links Dude</h4>
      <Link to="/signin" className="admin-link">Admin Sign In</Link>
    </nav>
  );
}

export default Navbar;

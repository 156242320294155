import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAhjYkpvQOp6nNw-z8dOwyJTyM9O7bpwd4",
  authDomain: "thelinksdude-645a3.firebaseapp.com",
  projectId: "thelinksdude-645a3",
  storageBucket: "thelinksdude-645a3.appspot.com",
  messagingSenderId: "775384248413",
  appId: "1:775384248413:web:ac0bf38ee3c798e02e9db4",
  measurementId: "G-GPVETVQRJ5"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const firestore = getFirestore(app); 

export { auth, analytics, firestore, }; 